import React, { Component } from "react";
import { FormattedMessage, FormattedNumber, injectIntl } from "react-intl";
import classNames from "classnames";
import Layout from "../shared/Layout";
import services from "../../services";
import _ from "lodash";
import Auth from "../shared/Auth";
import { Link, Route } from "react-router-dom";
import withRouter from "../../util/withRouter";
import ActionMenu from "../shared/ActionMenu";
import InfiniteScrollOverride from "../shared/InfiniteScrollOverride";
import appState from "../../state/AppStateContainer";
import HeaderTitle from "../shared/HeaderTitle";
import Select from "react-select";
import moment from "moment";
import $ from "jquery";
import AdminForm from "./AdminForm";

export class AdminList extends Component {
  state = {
    data: [],
    roles: [],
    hasMore: true,
    pageIndex: -1,
    pageSize: 10,
    searchFilter: "",
    statusFilter: "all",
    rolesFilter: [],
    updatingRowId: "",
  };

  getLocalizedName(o) {
    return appState.state.language === "en"
      ? _.get(o, "name.value_en") || _.get(o, "englishName")
      : _.get(o, "name.value_ar") ||
          _.get(o, "arabicName") ||
          _.get(o, "name.value_en") ||
          _.get(o, "englishName");
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (!this.props.match.params.isFormOpen) {
        if (_.get(this, "props.location.state.isSubmitted") === true) {
          this.setState({
            pageIndex: -1,
            data: [],
            hasMore: true,
            searchFilter: this.searchInput.value,
            statusFilter: this.statusSelect.value,
            disableFlag: true,
          });
        } else {
          this.setState({ updatingRowId: "" });
        }
      }
    }
  }

  async componentDidMount() {
    try {
      services.stickyGridHeader();
      const options = { alphabeticalOrder: true };

      this.setState(
        {
          roles: (
            await services.getRoles("", undefined, undefined, options)
          ).map((r) => ({ label: r.name, value: r.id })),
        },
        () => {
          this.setState({ hasMore: true });
        }
      );
    } catch (e) {
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    }
  }

  async loadMore() {
    this.setState(
      (currentState) => {
        return {
          pageIndex: currentState.pageIndex + 1,
          hasMore: false,
          loading: true,
        };
      },
      async () => {
        const options = { progressBar: true, alphabeticalOrder: true };
        const pageIndex = 0;
        const pageData = await services.getAdmins(
          this.state.searchFilter,
          this.state.statusFilter,
          this.state.rolesFilter,
          this.state.pageIndex,
          this.state.pageSize,
          options
        );

        const summary =
          pageIndex === 0
            ? await services.getAdmins(
                this.state.searchFilter,
                this.state.statusFilter,
                this.state.rolesFilter,
                undefined,
                undefined,
                { ...options, summary: 1 }
              )
            : { count: this.state.count };
        this.setState((currentState) => {
          return {
            data: currentState.data.concat(pageData),
            hasMore: pageData.length > 0,
            disableFlag: false,
            count: summary.count,
            loading: false,
          };
        });
      }
    );
  }

  resetData(e) {
    this.setState({
      pageIndex: -1,
      data: [],
      hasMore: true,
      searchFilter: this.searchInput.value,
      statusFilter: this.statusSelect.value,
      disableFlag: true,
    });
    if (e) e.preventDefault();
  }

  resetPage(e) {
    this.setState({
      data: [],
      hasMore: true,
      pageIndex: -1,
      searchFilter: "",
      statusFilter: "all",
      rolesFilter: [],
      disableFlag: true,
    });
    this.searchInput.value = "";
    this.statusSelect.value = "all";
    e.preventDefault();
  }

  render() {
    const { intl } = this.props;
    return (
      <Auth requireAuth={true} roles={["sadmin"]}>
        <Layout hidden={this.props.match.params.isFormOpen} loading={this.state.loading}>
          <HeaderTitle id="admins.admins" defaultMessage="Admins" />
          <div className="main-container">
            <div className="table-header clearfix">
              <form className="row" onSubmit={this.resetData.bind(this)}>
                <div className="col-xs-3 pull-right">
                  <div className="panel-group-buttons pull-right">
                    <Link className="btn btn-special " to="/admin">
                      <span className="fa fa-plus" />{" "}
                      <FormattedMessage
                        id="admin.newAdmin"
                        defaultMessage="Create Admin"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-md-2 col-sm-4 col-xs-6">
                  <div className="has-feedback">
                    <input
                      type="text"
                      ref={(c) => {
                        this.searchInput = c;
                      }}
                      className="form-control"
                      placeholder={intl.formatMessage({
                        id: "global.search",
                        defaultMessage: "Search",
                      })}
                    />
                    <i className="glyphicon glyphicon-search form-control-feedback" />
                  </div>
                </div>
                <div className="col-md-2 col-sm-4 col-xs-6">
                  <select
                    ref={(c) => {
                      this.statusSelect = c;
                    }}
                    onChange={this.resetData.bind(this)}
                    className="form-control"
                  >
                    <option value="all">
                      {intl.formatMessage({
                        id: "operator.status",
                        defaultMessage: "Status",
                      })}
                    </option>
                    <option value="active">
                      {intl.formatMessage({
                        id: "operator.active",
                        defaultMessage: "Active",
                      })}
                    </option>
                    <option value="suspended">
                      {intl.formatMessage({
                        id: "operator.suspended",
                        defaultMessage: "Suspended",
                      })}
                    </option>
                  </select>
                </div>

                <div className="col-md-3 col-sm-4 col-xs-6">
                  <Select
                    isMulti
                    options={this.state.roles}
                    styles={{
                      control: (styles) => ({ ...styles, fontSize: "14px" }),
                    }}
                    placeholder={intl.formatMessage({
                      id: "admin.selectRoles",
                      defaultMessage: "Select Roles",
                    })}
                    value={this.state.roles.filter(
                      (r) => this.state.rolesFilter.indexOf(r.value) >= 0
                    )}
                    onChange={(c) => {
                      this.setState(
                        {
                          rolesFilter: c.map((c) => c.value),
                        },
                        this.resetData.bind(this)
                      );
                    }}
                  />
                </div>

                <div className="col">
                  <div className="tooltip-master">
                    <button
                      // href="javascript:;"
                      className="btn btn-primary"
                      onClick={this.resetData.bind(this)}
                      disabled={this.state.disableFlag}
                    >
                      <i className="material-icons">search</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.filter"
                        defaultMessage="Filter"
                      />
                    </span>
                  </div>
                </div>
                <div className="col">
                  <div className="tooltip-master">
                    <button
                      // href="javascript:;"
                      className="btn btn-primary"
                      onClick={this.resetPage.bind(this)}
                      disabled={this.state.disableFlag}
                    >
                      <i className="material-icons">settings_backup_restore</i>
                    </button>
                    <span className="tooltiptext">
                      <FormattedMessage
                        id="global.resetFilter"
                        defaultMessage="Reset Filter"
                      />
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div className="row">
              <div className="col-md-12">
                <FormattedMessage
                  id="global.totalCount"
                  defaultMessage="Total Count"
                />

                <strong>
                  {" "}
                  <FormattedNumber
                    style="decimal"
                    maximumFractionDigits={0}
                    minimumFractionDigits={0}
                    value={this.state.count || 0}
                  />{" "}
                </strong>
              </div>
            </div>

            <div className="dashboard-table-container card-layout">
              <div className="table-responsive" style={{ zIndex: "auto" }}>
                <table className="table dashboard-table table-hover ">
                  <thead>
                    <tr onClick={() => {}}>
                      <th>
                        <FormattedMessage
                          id="operator.name"
                          defaultMessage="Name"
                        />
                      </th>

                      <th>
                        <FormattedMessage
                          id="operator.mobilePhone"
                          defaultMessage="Mobile Phone"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="admin.roles"
                          defaultMessage="Roles"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="global.lastLoginTime"
                          defaultMessage="Last Login Time"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="admin.verified"
                          defaultMessage="Verified"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="operator.status"
                          defaultMessage="Status"
                        />
                      </th>
                      {/* <th>
                    <FormattedMessage
                      id="global.actions"
                      defaultMessage="Actions"
                    />
                  </th> */}
                      <th style={{ width: "20px" }} />
                    </tr>
                  </thead>

                  <InfiniteScrollOverride
                    element="tbody"
                    className="abcd"
                    pageStart={0}
                    loadMore={this.loadMore.bind(this)}
                    hasMore={this.state.hasMore}
                    useWindow={$("body").height() > $(window).height()}
                    initialLoad={true}
                    loader={
                      <tr key="loader">
                        <td colSpan={6}>Loading...</td>
                      </tr>
                    }
                  >
                    {this?.state?.data?.length !== 0 ? (
                      this.state.data.map((w) => (
                        <tr
                          key={w.id}
                          onClick={() => this.props.navigate(`/admin/${w.id}`)}
                          className="cases-trow link-row"
                        >
                          <td>{w.adminName}</td>
                          <td>{w.mobilePhone}</td>
                          <td>
                            {w.roles.map((r) => (
                              <span
                                key={r.id}
                                style={{
                                  backgroundColor: "silver",
                                  borderRadius: "5px",
                                  color: "black",
                                  padding: "5px",
                                  marginRight: "5px",
                                }}
                              >
                                {r.name}
                              </span>
                            ))}
                          </td>
                          <td>
                            {w.lastLoginTime
                              ? moment(w.lastLoginTime).format("lll")
                              : undefined}
                          </td>
                          <td
                            className={classNames({
                              "col-status-green": w.isVerified,
                              "col-status-red": !w.isVerified,
                            })}
                          >
                            <a>
                              {w.isVerified && (
                                <FormattedMessage
                                  id="adminList.true"
                                  defaultMessage="Yes"
                                />
                              )}
                              {!w.isVerified && (
                                <FormattedMessage
                                  id="adminList.false"
                                  defaultMessage="No"
                                />
                              )}
                            </a>
                          </td>
                          <td
                            className={classNames({
                              "col-status-green": w.isActive,
                              "col-status-red": !w.isActive,
                            })}
                          >
                            <a>
                              {w.isActive && (
                                <FormattedMessage
                                  id="operator.active"
                                  defaultMessage="Active"
                                />
                              )}
                              {!w.isActive && (
                                <FormattedMessage
                                  id="operator.suspended"
                                  defaultMessage="Suspended"
                                />
                              )}
                            </a>
                          </td>
                          <td style={{ position: "relative" }}>
                            <ActionMenu
                              visible={this.state.visible}
                              menuStyle={{
                                backgroundColor: "#E9E9E9",
                              }}
                            >
                              <Link
                                style={{ margin: "10px", cursor: "pointer" }}
                                to={`/admin/${w.id}`}
                              >
                                <span className="fa fa-pencil" />{" "}
                                <FormattedMessage
                                  id="global.edit"
                                  defaultMessage="Edit"
                                />
                              </Link>

                              {/* <Link
                              style={{
                                margin: "10px",
                                cursor: "pointer",
                              }}
                              to={`/userActivities/${w.id}`}
                            >
                              <span className="fa fa-history" />{" "}
                              <FormattedMessage
                                id="global.viewHistory"
                                defaultMessage="View History"
                              />
                            </Link> */}
                            </ActionMenu>
                          </td>
                          {/* <td className="col-blue">
                        <div className="tooltip-master">
                          <a href="operator-add.html">
                            <i className="material-icons">edit</i>
                          </a>
                          <span className="tooltiptext">Edit</span>
                        </div>
                        <div className="tooltip-master">
                          <a href="javascript:;">
                            <i className="material-icons">launch</i>
                          </a>
                          <span className="tooltiptext">Login As</span>
                        </div>
                      </td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          <FormattedMessage
                            id="global.noData"
                            defaultMessage="No data available"
                          />
                        </td>
                      </tr>
                    )}
                  </InfiniteScrollOverride>
                </table>
              </div>
            </div>
          </div>
        </Layout>
      </Auth>
    );
  }
}

export default withRouter(injectIntl(AdminList));
