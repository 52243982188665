import React, { useCallback, useEffect, useRef, useState } from "react";
import withRouter from "../../util/withRouter";
import { FormattedMessage, injectIntl } from "react-intl";
import Auth from "../shared/Auth";
import Layout from "../shared/Layout";
import HeaderTitle from "../shared/HeaderTitle";
import { Tabs, Tab } from "react-bootstrap";
import AddCarProgress from "../shared/AddCarProgress";
import ViewCarProgress from "../shared/ViewCarProgress";
import Services from "../../services";
import { ActivitiesEnum } from "../../staticData/Activities";
import EstimationService from "../../services/estimationService";
import AddRecommendation from "../shared/AddRecommendation";
import ViewRecommendations from "../shared/ViewRecommendations";
import Appointments from "./appointments";
import EstimateProgress from "./estimations";
import QuotationProgress from "./quotations";
import { repairStatus } from "../../util/utils";
import CheckAccess from "../shared/CheckAccess";
import quotationService from "../../services/quotationService";
import { get, values } from "lodash";
import { NotificationManager } from "react-notifications";
import "./style.css";
import { useLocation } from "react-router";

const Track = (props) => {
  const recommendedFormRef = useRef(null);
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState([]);
  const [cndProgress, setCndProgress] = useState([]);
  const [carProgressModal, setCarProgressModal] = useState({
    add: false,
    view: false,
  });
  const [showRecommendationModal, setShowRecommendationModal] = useState({
    add: false,
    view: false,
  });
  const [carData, setCarData] = useState({});
  const [fastCollect, setFastCollect] = useState([]);
  const [recommendedOffers, setRecommendedOffers] = useState([]);
  const [firstCarDetails, setFirstCarDetails] = useState([]);
  const [trackFastCollect, setTrackFastCollect] = useState([]);
  const [cndTrackFastCollect, setCndTrackFastCollect] = useState([]);
  const [estimations, setEstimations] = useState([]);
  const [quotations, setQuotations] = useState([]);
  const [recommendedOfferAction, setRecommendedOfferAction] = useState({
    id: "",
    isActive: false,
  });
  const [carProgressAction, setCarProgressAction] = useState({
    progressId: "",
    messageId: "",
    isActive: false,
  });
  const [fastCollectBranch, setFastCollectBranch] = useState([]);
  const [cndFastCollectBranch, setCndFastCollectBranch] = useState([]);
  const { state } = useLocation();
  const isTrack = state?.fromTrack?.length > 0 || false;
  const [selectedTab, setSelectedTab] = useState(
    isTrack ? "cndAppointments" : "appointments"
  );
  const [phoneNumber, setPhoneNumber] = useState(
    isTrack > 0 ? state?.fromTrack : "+"
  );

  useEffect(() => {
    triggerSearchField();
  }, []);

  const triggerSearchField = () => {
    const { fromTrack = "" } = state || {};
    if (fromTrack?.length > 0) {
      const phone = fromTrack.replace(/^\+966/, "");
      inputRef.current.value = phone;
      recommendedFormRef.current.click();
    }
  };

  const getProgress = async () => {
    try {
      const progress = await Services.getProgress(phoneNumber);

      if (progress?.tracks) {
        const progressData = progress.tracks;

        progressData.forEach((car) => {
          car.forEach((est) => {
            let status =
              est.status && est.status.toLowerCase().replaceAll(" ", "_");
            est.status = status ? repairStatus.appointments[status] : undefined;
          });
        });

        progressData.forEach((carsEstimation) => {
          carsEstimation.sort((a, b) => a.status - b.status);
        });

        setProgress(progressData);
      }
      if (progress?.fastCollect?.length)
        setTrackFastCollect(progress?.fastCollect);
      if (progress?.fastCollectBranch?.length)
        setFastCollectBranch(progress?.fastCollectBranch);
    } catch (e) {
      console.error(e);
      setProgress([]);
    }
  };

  const getEstimations = async () => {
    try {
      const estimation = await EstimationService.getUserEstimations(
        phoneNumber
      );
      if (estimation) setEstimations(estimation);
    } catch (e) {
      console.error(e);
    }
  };

  const getQuotations = async () => {
    try {
      const quotations = await quotationService.getRequestedQuotes(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        phoneNumber,
        undefined
      );
      if (quotations) setQuotations(quotations);
    } catch (e) {
      console.error(e);
    }
  };

  const getCndAppointments = async () => {
    try {
      const progress = await Services.getCndTrack(phoneNumber);

      if (progress?.track) {
        const progressData = progress.track;

        progressData.forEach((car) => {
          car.forEach((est) => {
            est.status = est.status
              ? repairStatus.cnd_appointments[est.status]
              : undefined;
          });
        });

        setCndProgress(progressData);
      }
      if (progress?.fastCollect?.length)
        setCndTrackFastCollect(progress?.fastCollect);
      if (progress?.fastCollectBranch?.length)
        setCndFastCollectBranch(progress?.fastCollectBranch);
    } catch (err) {
      console.log(err);
      setCndProgress([]);
    }
  };

  const handlePhoneChange = (e) => setPhoneNumber("+966" + e.target.value);
  // const handlePhoneChange = (e) => setPhoneNumber(e.target.value);

  const handleCarProgressModal = async (modal, car = {}) => {
    let data;
    if (cndProgress) {
      data = cndProgress[0];
    }
    if (modal === "add")
      setCarProgressModal({ add: !carProgressModal.add, view: false });
    else if ("view") {
      if (!carProgressModal.view) {
        const payload =
          selectedTab === "cndAppointments"
            ? {
                carPlateNumber: car.carPlateNumber,
                phoneNumber,
                appointmentId: (data && data[0]?.appointmentId) || "",
              }
            : { carPlateNumber: car.carPlateNumber, phoneNumber };
        await getFastCollectData(payload);
      }
      setCarProgressModal({ add: false, view: !carProgressModal.view });
    }
    setCarData(car);
  };

  const getFastCollectData = async (filters) => {
    setLoading(true);
    try {
      const data = await Services.getFastCollectInfo(filters);
      setFastCollect(data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const handleViewRecommendations = async (carPlateNumber) => {
    setRecommendedOffers([]);
    let _data;
    if (cndProgress) {
      _data = cndProgress[0];
    }
    setLoading(true);
    try {
      const data = await Services.getRecommendedOffers({
        phoneNumber,
        carPlateNumber,
        appointmentId:
          selectedTab === "cndAppointments"
            ? _data && _data[0]?.appointmentId
            : "",
      });
      setRecommendedOffers(data);
      setLoading(false);
      setShowRecommendationModal({
        view: true,
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleDisabledButton = useCallback(
    (carsEstimation, type) => {
      for (const obj of type === "appointments"
        ? trackFastCollect
        : cndTrackFastCollect) {
        if (
          (obj?.carPlateNumber ===
            `${carsEstimation?.plateNumber}${carsEstimation?.plateLetter}` &&
            obj?.isCustomerSatisfied) ||
          carsEstimation?.status !== 2
        )
          return true;
      }
      if (carsEstimation?.status !== 2) return true;
      return false;
    },
    [trackFastCollect, cndTrackFastCollect]
  );

  const fetchData = async (e) => {
    e && e.preventDefault();
    setLoading(true);
    setProgress([]);
    setCndProgress([]);
    setEstimations([]);
    setQuotations([]);
    await getProgress();
    await getEstimations();
    await getQuotations();
    await getCndAppointments();
    setLoading(false);
  };

  const handleRecommendedOffers = async () => {
    setLoading(true);
    setShowRecommendationModal(false);
    try {
      const result = await Services.updateRecommendedOffer(
        recommendedOfferAction
      );
      if (result?.statusCode === 200) await fetchData();
    } catch (e) {
      console.error(e);
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    }
  };

  const handleCarProgress = async () => {
    setLoading(true);
    setCarProgressModal(false);
    try {
      const response = await Services.updateCarProgress(carProgressAction);
      if (response?.message === "fastCollectUpsell.updatedSuccessfully")
        await fetchData();
    } catch (e) {
      console.error(e);
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    }
  };

  const handleSelectTab = (selectedTab) => setSelectedTab(selectedTab);

  return (
    <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
      <Layout loading={loading}>
        <HeaderTitle
          id="track.heading"
          defaultMessage="Track car repair progress"
        />
        <div className="table-header clearfix track-tabs">
          <div className="track-header">
            <h2>
              <FormattedMessage
                id="track.mainHeading"
                defaultMessage="Get user's car repair progress"
              />
            </h2>

            <form onSubmit={fetchData}>
              <div className="select-branch phone">
                <label htmlFor="phone" className="mb-0">
                  <FormattedMessage
                    id="track.phoneNumber"
                    defaultMessage="Phone number:"
                  />
                  <div className="form-group mt-2 d-inline-block p-0">
                    <span className="border-end country-code px-2 mx-1">
                      +966
                    </span>
                    <input
                      type="number"
                      className="form-control"
                      id="ec-mobile-number"
                      aria-describedby="emailHelp"
                      placeholder="Enter phone number"
                      defaultValue={phoneNumber}
                      onChange={handlePhoneChange}
                      required={true}
                      ref={inputRef}
                    />
                  </div>
                  {/* <input
                    type="number"
                    id="phone"
                    className="form-control"
                    placeholder="Enter phone number"
                    defaultValue={phoneNumber}
                    onChange={handlePhoneChange}
                    required={true}
                  /> */}
                </label>

                <div className="col force--inline py-0">
                  <div className="tooltip-master">
                    <button
                      type="submit"
                      ref={recommendedFormRef}
                      className="btn btn-primary"
                      disabled={!phoneNumber}
                    >
                      <i className="material-icons">search</i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {progress?.length || cndProgress?.length || estimations?.length ? (
            <Tabs
              id="fill-tab-example"
              className="mt-4 mt-md-0 mb-4"
              onSelect={handleSelectTab}
              defaultActiveKey={selectedTab}
            >
              <Tab
                eventKey="appointments"
                title={
                  <FormattedMessage
                    id="track.appointments"
                    defaultMessage="Appointments"
                  />
                }
              >
                <CheckAccess activityId={ActivitiesEnum.view_appointment_tab}>
                  <Appointments
                    type="appointments"
                    progress={progress}
                    fastCollectBranch={fastCollectBranch}
                    handleDisabledButton={handleDisabledButton}
                    handleCarProgressModal={handleCarProgressModal}
                    setFirstCarDetails={setFirstCarDetails}
                    setShowRecommendationModal={setShowRecommendationModal}
                    handleViewRecommendations={handleViewRecommendations}
                  />
                </CheckAccess>
              </Tab>

              <Tab
                eventKey="estimations"
                title={
                  <FormattedMessage
                    id="track.estimation"
                    defaultMessage="Estimations"
                  />
                }
              >
                <CheckAccess activityId={ActivitiesEnum.view_estimation_tab}>
                  <EstimateProgress
                    estimations={estimations}
                    loader={loading}
                    setLoading={setLoading}
                  />
                </CheckAccess>
              </Tab>

              <Tab
                eventKey="quotations"
                title={
                  <FormattedMessage
                    id="track.quotations"
                    defaultMessage="Quotations"
                  />
                }
              >
                <CheckAccess activityId={ActivitiesEnum.view_quotaion_tab}>
                  <QuotationProgress
                    quotations={quotations}
                    loader={loading}
                    setLoading={setLoading}
                  />
                </CheckAccess>
              </Tab>
              <Tab
                eventKey="cndAppointments"
                title={
                  <FormattedMessage
                    id="track.cndAppointments"
                    defaultMessage="CND Appointments"
                  />
                }
              >
                <CheckAccess activityId={ActivitiesEnum.view_appointment_tab}>
                  <Appointments
                    type="cnd_appointments"
                    progress={cndProgress}
                    fastCollectBranch={cndFastCollectBranch}
                    handleDisabledButton={handleDisabledButton}
                    handleCarProgressModal={handleCarProgressModal}
                    setFirstCarDetails={setFirstCarDetails}
                    setShowRecommendationModal={setShowRecommendationModal}
                    handleViewRecommendations={handleViewRecommendations}
                  />
                </CheckAccess>
              </Tab>
            </Tabs>
          ) : null}
        </div>
      </Layout>

      <AddCarProgress
        show={carProgressModal.add}
        onCancel={handleCarProgressModal}
        carData={carData}
        phoneNumber={phoneNumber}
        intl={props.intl}
        progress={cndProgress}
        selectedTab={selectedTab}
      />
      <AddRecommendation
        {...{
          showRecommendationModal,
          setShowRecommendationModal,
          phoneNumber,
          getProgress,
          firstCarDetails,
          progress: cndProgress,
          selectedTab,
        }}
      />
      <ViewCarProgress
        {...{
          show: carProgressModal?.view,
          onCancel: handleCarProgressModal,
          data: fastCollect,
          setCarProgressAction,
          handleCarProgress,
        }}
      />
      <ViewRecommendations
        {...{
          showRecommendationModal,
          setShowRecommendationModal,
          data: recommendedOffers,
          handleRecommendedOffers,
          setRecommendedOfferAction,
        }}
      />
    </Auth>
  );
};

export default withRouter(injectIntl(Track));
